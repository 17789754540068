import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, FormsModule, ReactiveFormsModule, Validators } from '@angular/forms';
import { debounceTime, map, take } from 'rxjs/operators';
import { SubSink } from 'subsink';
import { Address, Customer } from '@webcoffee/interfaces';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { MatInputModule } from '@angular/material/input';
import { MatButtonModule } from '@angular/material/button';
import { NgxGpAutocompleteModule } from '@angular-magic/ngx-gp-autocomplete';

@Component({
    selector: 'webcoffee-address-form',
    templateUrl: './address-form.component.html',
    styleUrls: ['./address-form.component.scss'],
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, FormsModule, MatFormFieldModule, MatInputModule, DxSelectBoxModule, MatButtonModule, NgxGpAutocompleteModule],
})
export class AddressFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input() address!: Address | null;
    @Input() customerId!: string | null;
    @Input() noCustomer = false;
    @Output() addressOutput: EventEmitter<FormGroup> = new EventEmitter();
    @Output() geocodeAddress: EventEmitter<any> = new EventEmitter();

    subs = new SubSink();

    addressFormGroup: FormGroup = new FormGroup({
        city: new FormControl('', Validators.required),
        street: new FormControl('', Validators.required),
        county: new FormControl(''),
        number: new FormControl(''),
        postal_code: new FormControl(''),
        details: new FormControl(''),
        customerId: new FormControl(''),
        latitude: new FormControl(''),
        longitude: new FormControl(''),
    });

    customerOptions!: { label: string; value: string }[];
    searchShippingAddress = '';

    constructor(private http: HttpClient) {}

    ngOnInit(): void {
        this.http
            .get<Customer[]>('api/customers')
            .pipe(take(1))
            .subscribe((customers) => {
                this.customerOptions = customers.map((customer) => ({ label: `${customer.firstname} ${customer.lastname}`, value: customer.id as string }));
            });
        this.subs.sink = this.addressFormGroup.valueChanges.pipe(debounceTime(250)).subscribe((values) => this.addressOutput.emit(this.addressFormGroup));
    }

    ngOnChanges() {
        if (this.address) {
            this.addressFormGroup.patchValue({ ...this.address, customerId: this.address.customer?.id });
            this.addressFormGroup.markAllAsTouched();
        }
        if (this.customerId) {
            this.addressFormGroup.patchValue({ customerId: this.customerId });
        }
    }

    onAddressChange(e: any) {
        const parsedAddress = e?.address_components?.reduce((acc, val) => {
            return Object.assign(
                acc,
                val.types.reduce((intAcc, type) => {
                    let value = val.long_name;

                    if (['sublocality', 'sublocality_level_1', 'administrative_area_level_1'].includes(type)) {
                        value = val.long_name
                            .split(' ')
                            .filter((w) => !w.toLowerCase().startsWith('jude'))
                            .join(' ');
                    }
                    return Object.assign(intAcc, { [type]: value });
                }, {}),
            );
        }, {});

        this.addressFormGroup.patchValue({
            city: parsedAddress.locality,
            street: parsedAddress.route ?? parsedAddress.street_address,
            county: parsedAddress.sublocality ?? parsedAddress.sublocality_level_1 ?? parsedAddress.administrative_area_level_1,
            latitude: parsedAddress.lat,
            longitude: parsedAddress.lng,
            postal_code: parsedAddress.postal_code,
            number: parsedAddress.number ?? parsedAddress.street_number,
        });
        this.searchShippingAddress = '';
    }

    pressedEnter(e: Event) {
        e.stopImmediatePropagation();
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
