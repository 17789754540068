import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, OnChanges, OnDestroy, OnInit, Output } from '@angular/core';
import { AbstractControl, FormControl, FormGroup, ReactiveFormsModule, Validators } from '@angular/forms';
import { take } from 'rxjs';
import { SubSink } from 'subsink';
import { Address, Contact, Customer, LegalInfo } from '@webcoffee/interfaces';
import { ToasterService } from '../../utils/services/toastr.service';
import { CommonModule } from '@angular/common';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { DxSelectBoxModule } from 'devextreme-angular/ui/select-box';
import { DateTime } from 'luxon';
import { MatButtonModule } from '@angular/material/button';

@Component({
    selector: 'webcoffee-legal-info-form',
    templateUrl: './legal-info-form.component.html',
    styleUrls: ['./legal-info-form.component.scss'],
    standalone: true,
    imports: [CommonModule, ReactiveFormsModule, MatFormFieldModule, MatInputModule, MatCheckboxModule, MatButtonModule],
})
export class LegalInfoFormComponent implements OnInit, OnChanges, OnDestroy {
    @Input() legalInfo!: LegalInfo | null;
    @Input() customerId!: string;
    @Output() legalInfoOutput: EventEmitter<FormGroup> = new EventEmitter();

    subs = new SubSink();

    customerAddresses!: Address[];
    customerContacts!: Contact[];

    constructor(private http: HttpClient, private toastr: ToasterService) {}

    legalInfoFormGroup: FormGroup = new FormGroup({
        name: new FormControl({ value: '', disabled: false }, Validators.required),
        vatCode: new FormControl('', Validators.required),
        regCom: new FormControl({ value: '', disabled: false }, [Validators.required]),
        bank: new FormControl({ value: '', disabled: false }),
        iban: new FormControl({ value: '', disabled: false }, Validators.pattern(/^RO[0-9]{2}[A-Z]{4}[A-z0-9]{16}$/)),
        representative: new FormControl('', Validators.required),
        tva: new FormControl({ value: false, disabled: false }),
        address: new FormGroup({
            city: new FormControl('', Validators.required),
            county: new FormControl('', Validators.required),
            street: new FormControl('', Validators.required),
            number: new FormControl(''),
            details: new FormControl('', Validators.required),
            postal_code: new FormControl(''),
        }),
        customerId: new FormControl({ value: '', disabled: false }),
    });

    customerOptions!: { label: string; value: string }[];

    ngOnInit(): void {
        this.subs.sink = this.legalInfoFormGroup.valueChanges.subscribe((values) => {
            this.legalInfoOutput.emit(this.legalInfoFormGroup);
        });
        this.http
            .get<Customer[]>('api/customers')
            .pipe(take(1))
            .subscribe((customers) => {
                this.customerOptions = customers.map((customer) => ({ label: `${customer.firstname} ${customer.lastname}`, value: customer.id as string }));
            });
    }

    ngOnChanges(): void {
        if (this.legalInfo) {
            this.legalInfoFormGroup.patchValue({
                ...this.legalInfo,
                customerId: this.legalInfo?.customer?.id,
                addressId: this.legalInfo?.address?.id,
                contactId: this.legalInfo?.contact?.id,
            });
        }
        if (this.customerId) {
            this.legalInfoFormGroup.patchValue({ customerId: this.customerId });
        }
    }

    checkVatCode() {
        this.http
            .post('/api/legal-infos/anaf', { cui: +this.legalInfoFormGroup.get('vatCode')?.value, data: DateTime.local().toFormat('yyyy-LL-dd') })
            .pipe(take(1))
            .subscribe({
                next: (company: any) => {
                    if (company) {
                        const general = company.date_generale;

                        this.legalInfoFormGroup.patchValue({
                            name: general.denumire,
                            vatCode: general.cui,
                            regCom: general.nrRegCom,
                            tva: company.inregistrare_scop_Tva.scpTVA,
                            address: {
                                city: company.adresa_sediu_social?.sdenumire_Judet ?? company.adresa_domiciliu_fiscal?.ddenumire_Judet,
                                county: company.adresa_sediu_social?.sdenumire_Localitate ?? company.adresa_domiciliu_fiscal?.ddenumire_Localitate,
                                street: company.adresa_sediu_social?.sdenumire_Strada ?? company.adresa_domiciliu_fiscal?.ddenumire_Strada,
                                number: company.adresa_sediu_social?.snumar_Strada ?? company.adresa_domiciliu_fiscal?.dnumar_Strada,
                                details: general.adresa.split(',').slice(4).join(', ').trim(),
                            },
                        });
                    }
                },
                error: (err) => this.toastr.error('Verificarea ANAF nu a functionat. Va rugam incercati mai tarziu'),
            });
    }

    get addressFormGroup() {
        return this.legalInfoFormGroup.controls.address as FormGroup;
    }

    ngOnDestroy(): void {
        this.subs.unsubscribe();
    }
}
