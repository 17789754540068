<div [formGroup]="addressFormGroup">
    <div class="row">
        <div class="col-md-12">
            <mat-form-field>
                <mat-label>Cauta adresa</mat-label>
                <input
                    matInput
                    ngx-gp-autocomplete
                    (onAddressChange)="onAddressChange($event)"
                    (keydown.enter)="pressedEnter($event)"
                    [(ngModel)]="searchShippingAddress"
                    [ngModelOptions]="{ standalone: true }" />
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-6">
            <mat-form-field>
                <mat-label>Oras</mat-label>
                <input matInput type="text" formControlName="city" required />
                <mat-error *ngIf="addressFormGroup.controls.city?.touched && addressFormGroup.controls.city?.invalid && addressFormGroup.controls.city?.hasError('required')">
                    Orasul este obligatoriu
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6">
            <mat-form-field>
                <mat-label>Judet</mat-label>
                <input matInput type="text" formControlName="county" />
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-4">
            <mat-form-field>
                <mat-label>Strada</mat-label>
                <input matInput type="text" formControlName="street" required />
                <mat-error *ngIf="addressFormGroup.controls.street?.touched && addressFormGroup.controls.street?.invalid && addressFormGroup.controls.street?.hasError('required')">
                    Strada este obligatorie
                </mat-error>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
            <mat-form-field>
                <mat-label>Numar</mat-label>
                <input matInput type="text" formControlName="number" />
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
            <mat-form-field>
                <mat-label>Cod Postal</mat-label>
                <input matInput type="text" formControlName="postal_code" />
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12" [ngClass]="{ 'col-md-7': !noCustomer }">
            <mat-form-field>
                <mat-label>Descriere</mat-label>
                <textarea matInput cdkTextareaAutosize type="text" formControlName="details"></textarea>
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-5" *ngIf="!noCustomer">
            <dx-select-box
                [dataSource]="customerOptions"
                height="3.3rem"
                placeholder="Selecteaza client"
                displayExpr="label"
                valueExpr="value"
                formControlName="customerId"
                [readOnly]="customerId ? true : false"
                [searchEnabled]="true"></dx-select-box>
        </div>
    </div>
</div>
