<div [formGroup]="legalInfoFormGroup">
    <div class="d-flex">
        <mat-form-field>
            <mat-label>CUI</mat-label>
            <input matInput type="number" formControlName="vatCode" />
            <mat-hint *ngIf="legalInfoFormGroup.valid">
                <span *ngIf="legalInfoFormGroup.controls.tva.value">Firma platitoare de TVA. CUI pe factura RO{{ legalInfoFormGroup.controls.vatCode.value }}</span>
                <span *ngIf="!legalInfoFormGroup.controls.tva.value">Firma neplatitoare de TVA</span>
            </mat-hint>
        </mat-form-field>
        <button mat-raised-button color="primary" class="m-2 px-4" (click)="checkVatCode()">Cauta</button>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-4">
            <mat-form-field>
                <mat-label>Nume</mat-label>
                <input matInput type="text" formControlName="name" />
                <mat-error *ngIf="legalInfoFormGroup.controls.name.invalid && legalInfoFormGroup.controls.name.touched && legalInfoFormGroup.controls.name.hasError('required')"
                    >Numele este obligatoriu</mat-error
                >
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
            <mat-form-field>
                <mat-label>Reprezentant</mat-label>
                <input matInput type="text" formControlName="representative" />
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
            <mat-form-field>
                <mat-label>Registrul Comertului</mat-label>
                <input matInput type="text" formControlName="regCom" />
            </mat-form-field>
        </div>
    </div>
    <div class="row">
        <div class="col-sm-12 col-md-4">
            <mat-form-field>
                <mat-label>Banca</mat-label>
                <input matInput type="text" formControlName="bank" />
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
            <mat-form-field>
                <mat-label>IBAN</mat-label>
                <input matInput type="text" formControlName="iban" />
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
            <mat-checkbox formControlName="tva">Platitor de TVA</mat-checkbox>
        </div>
    </div>
    <div class="row" formGroupName="address">
        <div class="col-sm-12 col-md-6">
            <mat-form-field>
                <mat-label>Oras</mat-label>
                <input matInput type="text" formControlName="city" />
                <mat-error
                    *ngIf="this.addressFormGroup.controls.city.invalid && this.addressFormGroup.controls.city.touched && this.addressFormGroup.controls.city.hasError('required')"
                    >Orasul este obligatoriu</mat-error
                >
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-6">
            <mat-form-field>
                <mat-label>Judet</mat-label>
                <input matInput type="text" formControlName="county" />
                <mat-error
                    *ngIf="
                        this.addressFormGroup.controls.county.invalid && this.addressFormGroup.controls.county.touched && this.addressFormGroup.controls.county.hasError('required')
                    "
                    >Judetul este obligatoriu</mat-error
                >
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
            <mat-form-field>
                <mat-label>Strada</mat-label>
                <input matInput type="text" formControlName="street" />
                <mat-error
                    *ngIf="
                        this.addressFormGroup.controls.street.invalid && this.addressFormGroup.controls.street.touched && this.addressFormGroup.controls.street.hasError('required')
                    "
                    >Strada este obligatorie</mat-error
                >
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
            <mat-form-field>
                <mat-label>Numar</mat-label>
                <input matInput type="text" formControlName="number" />
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-4">
            <mat-form-field>
                <mat-label>Cod postal</mat-label>
                <input matInput type="text" formControlName="postal_code" />
            </mat-form-field>
        </div>
        <div class="col-sm-12 col-md-12">
            <mat-form-field>
                <mat-label>Detalii</mat-label>
                <input matInput type="text" formControlName="details" />
            </mat-form-field>
        </div>
    </div>
</div>
